<template>
  <v-dialog persistent  
    v-model="activeModal"
    :max-width="variables.widthModal"
    
    class="modal-cadastro">
    <template v-slot:activator="{}">
      <v-btn 
        text
        icon 
        @click="openModal"
        :color="colorFilter">
          <v-icon dark>mdi-filter</v-icon>
        </v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      <v-form>
        <v-card-title class="modal-cadastro-titulo">
          Filtro

          <v-spacer></v-spacer>
          <v-btn class="ma-2" outlined color="primary" @click="addItem">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="modal-cadastro-form">
          <!-- <v-row>
            <v-col>
              <v-menu
                v-if="disableLineFilter"
                v-model="menuRangeDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dataRangeText"
                    label="Período"
                    single-line
                    hide-details
                    v-on:keyup.delete="clearDate()"
                    v-on="on"
                    readonly
                  >
                    <template v-slot:prepend>
                      <v-icon>
                        event
                      </v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dateRange"
                  range
                  no-title
                  scrollable
                  locale="pt-br"
                  :min="dateMin"
                  :color="variables.colorPrimary">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="clearDate">Limpar</v-btn>
                    <v-btn text color="primary" @click="closeDate">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row> -->
          <v-row v-for="(item, key, index) in form" :key="index" class="pa-0">
            <v-col cols="12" sm="4">
              <v-select
                :items="listaItensSelect"
                label="Seleção"
                v-model="item.type" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="selectSign"
                label="Sinal"
                v-model="item.sign" />
            </v-col>
            <v-col cols="11" sm="4">
              <v-menu
                v-model="item.menuDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">

                 <template v-slot:activator="{ on }">
                  <v-text-field v-model="item.search" @input="testeDate(item)">
                    <template v-slot:append>
                      <v-icon v-on="on">
                        event
                      </v-icon>
                    </template>
                  </v-text-field>
                 </template>

                <v-date-picker
                  v-model="item.date"
                  no-title
                  scrollable
                  locale="pt-br"
                  @input="closeModalDatePicker(item)"
                  :color="variables.colorPrimary">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="clearDateFilter(item)">Limpar</v-btn>
                    <v-btn text color="primary"  @click="closeModalDatePicker(item)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="1" sm="1" class="d-flex justify-center align-center">
              <v-btn fab dark small color="red" class="fab-remove-item" @click="removeItem(key)">
                <v-icon dark>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="clearFilter" class="br-btn br-btn-cancelar">Limpar</v-btn>
          <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loading">Filtrar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { forEach, size } from 'lodash'
import moment from 'moment'
import { errorSnackbar } from '@/core/service/utils'
import Events from '@/core/service/events'
import { mapActions } from 'vuex'

/**
 * MIXINS Cadastro
 */
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'

export default {
  name: 'ModalFilter',
  mixins: [modalCadastro],
  components: {},
  props: {
    listaSelect: {
      type: Object,
      default: () => {}
    },
    configFilter: {
      type: Object,
      default: () => {}
    },
    getItens: {
      type: Function,
      required: true
    },
    jsonData: {
      type: Boolean,
      default: false
    },
    addlFilter: {
      type: Boolean,
      default: false
    },
    disableLineFilter: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    activeModal: false,
    loading: false,
    form: {
      item2: {
        type:"",
        search:"",
        sign:"ilike",
        menuDate:false,
        date:""
      }
    },
    qtdItemForm: 1,
    menuRangeDate: false,
    dateRange: [],
    dateRangeFormat: [],
    dateMin: '',
    ilike: 'ilike',
    isFilter: false
  }),

  mounted () {
    Events.$on('modalFilter::deactivate', () => this.isFilter = false)
  },
  
  watch: {
    dateRange (arrayDate) {
      this.dateMin = arrayDate[0]
      if (arrayDate.length === 2) {
        this.dateMin = ''
      }
      return false
    }
  },

  computed: {
    listaItensSelect () {
      let arraySelect = []
      forEach(this.propertiesToArray(this.listaSelect), item => {
        if (item !== 'parentId' ) {
          arraySelect.push({
            text: this.$gettext(item),
            value: item
          })
        }
      })
      return arraySelect
      // return this.$gettext('teste')
    },

    dataRangeText () {
      const self = this
      if (this.dateRange) {
        const date = [ ...this.dateRange ]
        this.dateRange.forEach((item, indice) => {
          if (indice === 0) date[indice] = 'Inicial ' + moment(item).format('DD/MM/YYYY')
          else date[indice] = 'Final ' + moment(item).format('DD/MM/YYYY')
        })
        self.dateRangeFormat = date
        return date.join(' - ')
      }

      return ''
    },

    selectSign () {
      return [
        { text: 'semelhante', value: this.ilike},
        { text: 'igual', value: '='},
        { text: 'maior que', value: '>'},
        { text: 'menor que', value: '<'},
        { text: 'maior ou igual', value: '>='},
        { text: 'menor ou igual', value: '<='},
        { text: 'in', value: 'In'},
      ]
    },

    colorFilter () {
      return this.isFilter ? 'primary' : ''
    }
  },
  methods: {
    ...mapActions('filter', ['setObjFilter']),
    testeDate (item) {
      const testeDate =  moment(item.search, 'DD/MM/YYYY', true)
      if (testeDate.isValid()) {
        item.date = moment(item.search, 'DD/MM/YYYY').format('YYYY-MM-DD')
      } else {
        item.date = ''
      }
    },

    closeModalDatePicker (item) {
      if (item.date) {
        item.search = moment(item.date).format('DD/MM/YYYY')
      }

      item.menuDate = false
    },

    propertiesToArray(obj) {
      if (!obj) return

      const isObject = val =>
        typeof val === 'object' && !Array.isArray(val);

      const addDelimiter = (a, b) =>
        a ? `${a}.${b}` : b;

      const paths = (obj = {}, head = '') => {
        return Object.entries(obj)
          .reduce((product, [key, value]) => {
            let fullPath = addDelimiter(head, key)
            return isObject(value) ?
              product.concat(paths(value, fullPath))
            : product.concat(fullPath)
            }, []);
  }

      return paths(obj);
    },

    closeModal () {
      this.activeModal = false
    },

    openModal () {
      this.activeModal = true
    },

    closeDate () {
      this.menuRangeDate = false
    },

    addItem () {
      this.qtdItemForm++
      this.$set(this.form, `item${this.qtdItemForm}`, {
        type: '',
        search: '',
        sign: this.ilike,
        menuDate: false,
        date: ''
      })
    },

    removeItem(item) {
      this.$delete(this.form, item)
    },

    clearDate () {
      this.dateRange = []
    },

    clearDateFilter (item) {
      item.date = ''
      item.search = ''
    },

    clearFilter () {
      this.dateRange = []
      this.form = {},
      this.isFilter = false
    },

    submit () {
      this.loading = true

      // valida se tem dados para serem filtrados para altear a cor do icone de filtro
      if (size(this.form) || size(this.dateRange)) {
        this.isFilter = true
      } else {
        this.isFilter = false
      }

      let _addlFilter = {}
      let _filter = {}

      // verifica se recebe uma props do novo filtro
      if (this.addlFilter) {
        forEach(this.form, item => {
          let search = item.date ? item.date : item.search

          // pega o primeiro elemento antes do . que geralmente é o nome da Tabela [Entites]
          const firstItem = item.type.split('.')[0]


          // quando for valor, trocar virgula por ponto
          if (item.type === 'value') {
            search = search.replace(',', '.')
          }

          if (item.type === 'cpf' || item.type === 'Entities.cpf' || item.type === 'Entity.cpf') {
            search = search.replace(/\D/g, '')
          }
          
          if (firstItem === 'Entities' || firstItem === 'Entity') {
            // _addlFilter[`Entities:jsonData.${item.type}_${item.sign}`] = item.sign === this.ilike ?  `%${search}%` : search
            _addlFilter[`${firstItem}:jsonData->>'${item.type.split('.')[1]}'_${item.sign}`] = item.sign === this.ilike ?  `%${search}%` : search
          } else {
            _addlFilter[`${item.type.replace('.', ':')}_${item.sign}`] = item.sign === this.ilike ?  `%${search}%` : search
          }
        })   
        
        if (this.configFilter.filter) {
          _addlFilter = { ..._addlFilter, ...this.configFilter.filter }
        }
      } else {
        forEach(this.form, item => {
          let search = item.date ? item.date : item.search
          if (item.type === 'cpf' || item.type === 'responsible.cpf' || item.type === 'cnpj') search = search.replace(/\D/g, '')

          if (this.configFilter.table) {

            _filter[`${this.configFilter.table}:jsonData.${item.type}_${item.sign}`] = item.sign === this.ilike ?  `%${search}%` : search
          } else if (
              this.jsonData && 
              item.type !== 'id' &&
              item.type !== 'deleted' && 
              item.type !== 'created' &&
              item.type !== 'status') {
              _filter[`Entities:jsonData.${item.type}_${item.sign}`] = item.sign === this.ilike ?  `%${search}%` : search
            } else {
              _filter[`${item.type}_${item.sign}`] = item.sign === this.ilike ?  `%${search}%` : search
            }
        })
      }

      const objFilter = {
        page: 1,
        pageSize: this.$store.getters.ItensPaginacao,
        filterDtStart: this.dateRange.length > 1 ? `${this.dateRange[0]} 00:00:00` : null,
        filterDtEnd: this.dateRange.length > 1 ? `${this.dateRange[1]} 23:59:59` : null, 
      }

      if (size(_filter)) {
        objFilter['_filter'] = _filter
      } else {
        objFilter['_addlFilter'] = _addlFilter
      }

      this.setObjFilter(objFilter)
      this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao, ...objFilter, order: this.configFilter.order })
      .then(() => { 
        this.activeModal = false
        Events.$emit('paginacao::clear')
      })
      .catch( err => errorSnackbar(err.error) )
      .finally(() => this.loading = false)
    },
  },

  validations () {
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';
  
  .fab-remove-item {
    width: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
    min-width: 30px !important;
  }
</style>